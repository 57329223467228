<template>
    <div class="usePage">
        <div class="top-header">
            <div :class="[form.queryAllFlag ? '': 'top-on','top-title']" @click="topClick(true)">
                <span>全部</span>
                <span></span>
            </div>
            <div :class="[form.queryAllFlag ? 'top-on': '','top-title']" @click="topClick(false)">
                <span>待签署</span>
                <span></span>
            </div>
        </div>
        <div class="use-c">
            <div class="content">
                <van-pull-refresh style="width: 100%;" v-model="refreshing" @refresh="onRefresh">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                        offset="100"
                    >
                        <div class="info-item" v-for="item in signList" :key="item.signatureId">
                            <div class="title">
                                <span>{{item.contractName}}</span>
                                <span v-if="item.signStatus === 1">草稿</span>
                                <span v-if="item.signStatus === 2">签署中</span>
                                <span v-if="item.signStatus === 3">已完成</span>
                                <span v-if="item.signStatus === 4">已拒绝</span>
                                <span v-if="item.signStatus === 5">已撤回</span>
                                <span v-if="item.signStatus === 6">已过期</span>
                            </div>
                            <div class="info-flex">发起人: {{item.initiatorName}}</div>
                            <div class="info-flex">参与人: {{item.recipientsName}}</div>
                            <div class="info-flex">
                                <span>签约发送时间: {{ changeTime(item.sendTime, 'yyyy-MM-dd hh:mm:ss') }}</span>
                            </div>
                            <div class="info-flex">
                                <span>签约有效期: {{ changeTime(item.signDeadline, 'yyyy-MM-dd') }}</span>
                                <span v-if="item.signStatus === 2" @click="goPage(item)">去签署</span>
                                <span v-else @click="goPage(item)">查看详情</span>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
    </div>
</template>
<script>
// import { mapState, mapMutations } from "vuex";
import { querySignaturePage } from '@/util/api'
export default {
    data () {
        return {
            loading: false,
            finished: false,
            refreshing: false,
            signList: [],
            signTotal: 0,
            form: {
                page: {
                    current: 0,
                    size: 10
                },
                queryAllFlag: false
            }

        }
    },
    computed: {
        // ...mapState(["signList"])
    },
    created () {
        console.log(this.signList,'signList');
        // this.onLoad()
    },
    methods: {
        topClick (val) {
            this.form.queryAllFlag = val
            this.signList = []
            this.onRefresh()
        },
        onLoad (val) {
            this.form.page.current++
            if (val == 1) {
                this.form.page.current = val
            }
            querySignaturePage(this.form).then(res => {
                let data = res.records
                if (val == 1) {
                    this.signList = data
                    this.loading = false
                    this.refreshing = false
                    if (this.signList.length == res.total) {
                        this.finished = true
                    }
                } else {
                    this.signList = [...this.signList, ...data]
                    this.signTotal = res.total
                    this.loading = false
                    if (this.signList.length == res.total) {
                        this.finished = true
                    }
                }
            }).catch(() => {
                this.finished = true
            })
        },
        onRefresh () {
            this.finished = false
            this.loading = true
            this.onLoad(1)
        },
        changeTime(v, format='yyyy-MM-dd') {
            return new Date(v).format(format)
        },
        goPage (item) {
            console.log(item,'item');
            this.$router.push({path:'/home',query:{signatureId:item.signatureId,companyId:this.$route.query.companyId}})
        },
        // ...mapMutations(['linkCodeInfo'])
    }
}
</script>
<style lang="less" scoped>
.usePage{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 15px 10px;
    background: #0067E1;
    box-sizing: border-box;
    .top-header {
        display: flex;
    }
    .top-title{
        width: 50%;
        height: 49px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        justify-content: center;
        span {
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
        }
        span:nth-child(2) {
            display: inline-flex;
            margin-top: 4px;
            width: 24px;
            height: 4.5px;
            background: #FFFFFF;
            border-radius: 2.5px;
        }
    }
    .top-on {
        span {
            color: rgba(255, 255, 255, 0.4);
        }
        span:nth-child(2) {
            background: rgba(255, 255, 255, 0);
        }
    }
    .use-c {
        height: calc(100vh - 60px);
        overflow: auto;
    }
    .content{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        .info-item{
            width: 100%;
            background: #fff;
            border-radius: 8px;
            padding: 10px;
            box-sizing: border-box;
            margin-bottom: 20px;
            .title{
                width: 100%;
                padding: 10px 0;
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                align-items: center;
                span{
                    &:nth-of-type(1){
                        width: 70%;
                    }
                    &:nth-of-type(2){
                        color: #0067E1;
                    }
                }
            }
            .info-flex{
                display: flex;
                font-size: 14px;
                height: 30px;
                align-items: center;
                justify-content: space-between;
                span{
                    &:nth-of-type(2){
                        display: inline-block;
                        padding: 10px;
                        background: #0067E1;
                        color: #fff;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

</style>